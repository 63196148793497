var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-service-client"},[_c('v-data-table',{staticClass:"table-projet",attrs:{"headers":_vm.$i18n.locale == 'fr' ? _vm.headers : _vm.headersEn,"items":_vm.desserts,"calculate-widths":false,"fixed-header":true,"item-key":"id","single-select":_vm.singleSelect,"show-select":"","no-data-text":false
        ? _vm.$i18n.locale == 'fr'
          ? 'Chargement... Veuillez patienter'
          : 'Loading... Please wait'
        : _vm.$i18n.locale == 'fr'
        ? `Il n'y a aucune donnée à afficher dans ce tableau.`
        : 'There is no data to display in this table.'}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }