<template>
  <div class="content-service-client">
    <v-data-table
      class="table-projet"
      :headers="$i18n.locale == 'fr' ? headers : headersEn"
      :items="desserts"
      :calculate-widths="false"
      :fixed-header="true"
      item-key="id"
      :single-select="singleSelect"
      show-select
      :no-data-text="
        false
          ? $i18n.locale == 'fr'
            ? 'Chargement... Veuillez patienter'
            : 'Loading... Please wait'
          : $i18n.locale == 'fr'
          ? `Il n'y a aucune donnée à afficher dans ce tableau.`
          : 'There is no data to display in this table.'
      "
    >
    </v-data-table>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      singleSelect: false,
      selected: [],
      sortDirection: 'asc',
      currentSort: 'dossier',
      headers: [
        // {
        //   text: '',
        //   align: 'start',
        //   sortable: false,
        //   value: 'check_all',
        //   width: '40px !important',
        //   rowspan: 2,
        //   colspan: 1
        // },
        {
          text: 'Dossier',
          value: 'dossier',
          sortable: false,
          rowspan: 2,
          colspan: 1
        },
        {
          text: 'Statut',
          value: 'statut',
          sortable: false,
          rowspan: 2,
          colspan: 1
        },
        {
          text: 'Type SAV',
          value: 'type_sav',
          sortable: false,
          rowspan: 2,
          colspan: 1
        },
        {
          text: 'Statut SAV',
          value: 'statut_sav',
          sortable: false,
          rowspan: 2,
          colspan: 1
        },
        {
          text: 'Date SAV',
          value: 'date_sav',
          sortable: false,
          rowspan: 2,
          colspan: 1
        },
        {
          text: 'Motif',
          value: 'motif',
          sortable: false,
          rowspan: 2,
          colspan: 1
        },
        {
          text: 'Clôturée',
          value: 'clôturée',
          sortable: false,
          rowspan: 2,
          colspan: 1
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          rowspan: 2,
          colspan: 1
        }
      ],
      headersEn: [
        // {
        //   text: '',
        //   align: 'start',
        //   sortable: false,
        //   value: 'check_all',
        //   width: '40px !important',
        //   rowspan: 2,
        //   colspan: 1
        // },
        {
          text: 'Folder',
          value: 'dossier',
          sortable: false,
          rowspan: 2,
          colspan: 1
        },
        {
          text: 'Status',
          value: 'statut',
          sortable: false,
          rowspan: 2,
          colspan: 1
        },
        {
          text: 'Type SAV',
          value: 'type_sav',
          sortable: false,
          rowspan: 2,
          colspan: 1
        },
        {
          text: 'Status SAV',
          value: 'statut_sav',
          sortable: false,
          rowspan: 2,
          colspan: 1
        },
        {
          text: 'Date SAV',
          value: 'date_sav',
          sortable: false,
          rowspan: 2,
          colspan: 1
        },
        {
          text: 'Pattern',
          value: 'motif',
          sortable: false,
          rowspan: 2,
          colspan: 1
        },
        {
          text: 'Enclosed',
          value: 'clôturée',
          sortable: false,
          rowspan: 2,
          colspan: 1
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          rowspan: 2,
          colspan: 1
        }
      ],
      desserts: [
        {
          check_all: false,
          dossier: '29-06-2021',
          statut: 'TEST BRUNO/ROUSSEL',
          type_sav: 'Cell',
          statut_sav: 'Cell',
          date_sav: 'Cell',
          motif: 'Cell',
          clôturée: 'Cell',
          actions: 'Cell'
        },
        {
          check_all: false,
          dossier: '29-06-2021',
          statut: 'TEST BRUNO/ROUSSEL',
          type_sav: 'Cell',
          statut_sav: 'Cell',
          date_sav: 'Cell',
          motif: 'Cell',
          clôturée: 'Cell',
          actions: 'Cell'
        },
        {
          check_all: false,
          dossier: '29-06-2021',
          statut: 'TEST BRUNO/ROUSSEL',
          type_sav: 'Cell',
          statut_sav: 'Cell',
          date_sav: 'Cell',
          motif: 'Cell',
          clôturée: 'Cell',
          actions: 'Cell'
        },
        {
          check_all: false,
          dossier: '29-06-2021',
          statut: 'TEST BRUNO/ROUSSEL',
          type_sav: 'Cell',
          statut_sav: 'Cell',
          date_sav: 'Cell',
          motif: 'Cell',
          clôturée: 'Cell',
          actions: 'Cell'
        },
        {
          check_all: false,
          dossier: '29-06-2021',
          statut: 'TEST BRUNO/ROUSSEL',
          type_sav: 'Cell',
          statut_sav: 'Cell',
          date_sav: 'Cell',
          motif: 'Cell',
          clôturée: 'Cell',
          actions: 'Cell'
        }
      ],
      pagination: {
        page: 1,
        itemsPerPage: 50,
        totalItems: 10
      }
    }
  },
  methods: {
    sort() {}
  }
}
</script>
<style lang="scss">
.content-service-client {
  .table-projet {
    table {
      border-collapse: collapse;
    }

    height: 100% !important;
    max-height: 100% !important;
    .extra-border {
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background: #d8d8d8;
      z-index: 2;
    }
    .header-projets,
    .header-projets-2 {
      th {
        // border: 1px solid #e5e5e5;
        font-size: 11px;
        font-weight: 600;
        color: black;
        font-family: 'Montserrat', sans-serif;
        // text-transform: lowercase;
        white-space: nowrap;
        height: 25px !important;
        padding: 2px 5px;
        white-space: nowrap;
        // color: #5e5e5e;
        text-align: center !important;
        position: relative;
        font-size: 12px;
        line-height: 1.5;
        overflow: hidden;
      }
      .v-input--selection-controls {
        margin-top: 0px !important;
        padding-top: 0px !important;
      }
    }
    .header-projets-2 {
      border: 0px;
      position: sticky;
      top: 24px;
      background-color: #fff;
      box-shadow: unset !important;
      z-index: 1;
    }
    .header-projets {
      border: 0px;
      position: sticky;
      top: -1px;
      background-color: #fff;
      box-shadow: unset !important;
      z-index: 7;
      .th-checked {
        padding: 2px 11px;
        &.sticky-check {
          position: sticky;
          z-index: 3;
          left: 0px;
          background-color: #fff;
        }
      }
      .sticky-date {
        position: sticky;
        z-index: 3;
        left: 54px;
        background-color: #fff;
      }
    }
    .title-table {
      font-size: 13px;
      font-weight: 700;
      font-family: 'Roboto', sans-serif;
      color: rgba(0, 0, 0, 0.87);
      cursor: pointer;
    }
    thead {
      tr {
        th {
          font-weight: 500 !important;
          color: black !important;
          text-align: center !important;
        }
      }
    }
    tbody {
      td,
      th {
        padding: 2px 5px;
        white-space: nowrap;
        color: #5e5e5e;
        text-align: center !important;
        position: relative;
        font-size: 12px;
        line-height: 1.5;
        overflow: hidden;
      }
      .sticky-check {
        position: sticky;
        z-index: 3;
        left: 0px;
        background-color: #fff;
      }

      .sticky-date {
        position: sticky;
        z-index: 3;
        left: 54px;
        background-color: #fff;
      }
      .checked-table {
        padding: 2px 11px !important;
      }
      .v-input--selection-controls {
        margin-top: 0px !important;
        padding-top: 0px !important;
      }
    }
    .v-data-table__wrapper {
      max-height: calc(100vh - 338px) !important;
      height: calc(100vh - 338px) !important;
      margin-bottom: 0;
      overflow-y: auto;
      overflow-x: auto;
    }
    &.table-projet-filter {
      .v-data-table__wrapper {
        max-height: calc(100vh - 379px) !important;
        height: calc(100vh - 379px) !important;
        margin-bottom: 0;
        overflow-y: auto;
        overflow-x: auto;
      }
    }
    .v-data-table__wrapper::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    .v-data-table__wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 4px;
      background-color: rgba(238, 238, 238, 1) !important;
    }
    .v-data-table__wrapper::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(189, 189, 189, 1);
    }
  }
}
</style>
